import { Button } from '@bdo/kitchensink';
import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import {GetPageNumber} from "../../utilities/GetPageNumber"

export default function BackButton({ url = '/', type = 'danger', iconName = 'Back', ...props }) {

    const navigate = useNavigate();
    const { state } = useLocation();
    let pageNumber = GetPageNumber(state);

    function handleBack() {
        const getParameters = new URLSearchParams(window.location.search);
        var backUrl = getParameters.get('backUrl');
        if (backUrl) {
            navigate(backUrl, { state: { pgNo: pageNumber } });
        } else {
            navigate(url, { state: { pgNo: pageNumber } });
        }
    }

    return (
        <Button
            type={type}
            iconName={iconName}
            onClick={handleBack}
            {...props}
        >
            { props.children }
        </Button>
    );
}
