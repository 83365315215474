import { Button, Input, Alert, Switch } from '@bdo/kitchensink';
import React, { useEffect, useState } from 'react';
import Table from './Table';
import BackButton from '../misc/BackButton';
import { appRoutes, buildAppRoute } from '../../config/appRoutes';
import { useExternalCaptureData } from '../../hooks/useExternalCaptureData';
import { useLogging } from '../../hooks/useLogging';
import { passcodeActions, captureManageTabs } from '../../config/enum';

export default function List({ capture, setView }) {

    const {GetExternalCapturesForCaptureId} = useExternalCaptureData();
    const [allRecords, setAllRecords] = useState([]);
    const [records, setRecords] = useState([]);    
    const [isLoading, setIsLoading] = useState(true);
    const [hasError, setHasError] = useState(false);
    const [showAnonymous, setShowAnonymous] = useState(true);
    const logging = useLogging();
    
    const [search, setSearch] = useState('');
    const [displaySwitch, setDisplaySwitch] = useState(false);

    useEffect(() => {
        async function fetchData() {
            try {
                setIsLoading(true);
                setHasError(false)       
                
                const result = await GetExternalCapturesForCaptureId(capture.id);
                if (result && result.status === 200) {

                    let data = result.data.map((record) => ({
                        ...record,
                        recipientEmailAddresses: record.recipientEmailAddresses.replace(',',', ')
                    }))
                    .sort(function (a, b) {
                      return +new Date(a.modifiedDate) - +new Date(b.modifiedDate);
                    })
                    .reverse();
                    setRecords(data);
                    setAllRecords(data);
                    setDisplaySwitch(result.data.some(x => x.passcode === null));
                    setIsLoading(false);
                    return;
                }
                setHasError(true);
                logging.error("Failed to fetch passcodes, non success status code", { result: result });
            }
            catch (ex) {
                setHasError(true);
                logging.error("Failed to fetch passcodes, non success status code", { exception: ex });
            }

            setIsLoading(false);
        }
        fetchData();
    }, [capture.id]);

    const filterAnonymousResponses = () => {
        if (showAnonymous)
        {
            setRecords(allRecords.filter( r =>
                r.tag !== "Anonymous"
            ));
            setShowAnonymous(false);
        }
        else
        {
            
            setRecords(allRecords);
            setShowAnonymous(true);
        }
    }

    return (
        <>
            {hasError && <Alert type="error" message="There was an issue fetching records, please try refreshing the page." className="mb-3" />}
            <h2>Search existing <span className='highlighted-text'>External Capture</span> responses</h2>
            <div className='controls'>
                <Input
                    className='margin-tb'
                    type='text'
                    placeholder='Search existing'
                    onChange={({ target: { value } }) => {
                        setSearch(value);
                    }}
                />
                {capture.allowPasscodeResponses &&
                <Button
                    type='primary'
                    onClick={() => setView({
                        tab: captureManageTabs.Passcodes,
                        action: passcodeActions.Create
                    })}>
                    New passcode
                </Button>
                }                
            </div>
                        
            <Table
                isLoading={isLoading}
                records={records}
                filterTerm={search}
                setView={setView}
                captureId={capture.id}
                templateName={capture.templateName}
                displaySwitch={displaySwitch}
                filterAnonymousResponses={filterAnonymousResponses}                
                showAnon={showAnonymous}

            />
            <div className="d-flex flex-row justify-content-between mt-3 right-align">
                <BackButton url={buildAppRoute(appRoutes.capture.list)}>Back</BackButton>
            </div>

        </>

    );
}
