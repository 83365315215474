import { Button, Input, Table, Alert } from '@bdo/kitchensink';
import { Dropdown, Menu } from 'antd';
import React, { useEffect, useState } from 'react';
import { useCaptureApi } from '../../hooks/useCaptureApi';
import { apiRoutes, buildApiRoute } from '../../config/apiRoutes';
import { appRoutes, buildAppRoute } from '../../config/appRoutes';
import { useLogging } from '../../hooks/useLogging';
import { approvalStatus } from '../../config/enum';
import moment from 'moment';

export default function List() {
    const { apiPost, apiPut } = useCaptureApi();
    const [requests, setRequests] = useState([]);
    const [hasError, setHasError] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);
    const [tableLoading, setTableLoading] = useState(true);
    const [search, setSearch] = useState(undefined);
    const logging = useLogging();  

    useEffect(() => {
        async function fetch() {
            setTableLoading(true);

            const statusList = [
                approvalStatus.Pending,
                approvalStatus.InReview,
            ];
            const response = await apiPost(buildApiRoute(apiRoutes.approval.list), statusList);
            if (response && response.status === 200) {                
                setRequests(response.data.sort(function (a, b) {
                    return +new Date(a.dateRequested) - +new Date(b.dateRequested);
                  })
                  .reverse());
            }
            else
            {
                logging.error("Failed to fetch publication requests, non success status code", { response: response });
                setHasError(true);
            }
            
            setTableLoading(false);
        }
        fetch();
    }, []);

    const onButtonSelected =  async (approvalRequest, approvalStatus) => 
    {
        setErrorMessage(null);

        try {
            var payload = {
                id: approvalRequest.id,
                templateId: approvalRequest.templateId,
                status: approvalStatus,
                version: approvalRequest.templateVersion
            }

            const result = await apiPut(buildApiRoute(apiRoutes.approval.update), payload);

            if (result && result.status === 200) {
                setRequests(requests.map(x => (x.id === approvalRequest.id ? { ...x, status: approvalStatus } : x)));
                return true;
            }
            else if (result && result.status === 400) {
                setErrorMessage(result.data);
            }
            else {
                setHasError(true);                
                logging.error("Failed to update the approval request, non success status code", { result: result });
            }
        }
        catch (ex) {
            setHasError(true);
            logging.error("Failed to update the approval request, exception", { exception: ex });
        }    

        return false;
    }

    const onReview = async (request) => {
        let success = await onButtonSelected(request, approvalStatus.InReview);

        if (success) {
            window.open(buildAppRoute(appRoutes.template.view, { id: request?.templateId }), '_blank');
        }                  
    }

    const columns = [
        {
            title: 'Template name',
            key: 'templateName',
            dataIndex: 'templateName'
        },     
        {
            title: 'Version',
            key: 'templateVersion',
            dataIndex: 'templateVersion'
        },
        {
            title: 'Status',
            key: 'status',
            dataIndex: 'status'
        },
        {
            title: 'Date requested',
            key: 'dateRequested',
            dataIndex: 'dateRequested',
            render: (record) => (moment(record).format('DD MMM YYYY')),
            sorter: (a, b) => new Date(a.dateRequested) - new Date(b.dateRequested),
        }, {
            title: 'Requested by',
            key: 'requestedBy',
            dataIndex: 'requestedBy'
        },
        {
            title: 'Actions',
            key: 'actions',
            render: (request) => {
                const menu = (
                    <Menu>
                        { request.status === approvalStatus.InReview ?
                            <>
                                <Menu.Item onClick={() => onButtonSelected(request, approvalStatus.Pending)}  >                            
                                    <Button size='small' type='primary' iconName='Back' style={{ marginRight: 5 }} />
                                    <span> Cancel review</span>
                                </Menu.Item>
                                <Menu.Item onClick={() => window.open(buildAppRoute(appRoutes.template.view, { id: request?.templateId }), '_blank')}>                            
                                    <Button size='small' type='primary' iconName='View' style={{ marginRight: 5 }} />
                                    <span> Review </span>
                                </Menu.Item>
                                <Menu.Item onClick={() => onButtonSelected(request, approvalStatus.Approved)} >                            
                                    <Button size='small' type='primary' iconName='Accept' style={{ marginRight: 5 }} />
                                    <span> Approve </span>
                                </Menu.Item>
                                <Menu.Item  onClick={() => onButtonSelected(request, approvalStatus.Declined)} >
                                    <Button size='small' type='primary' iconName='Cancel' style={{ marginRight: 5 }}/>
                                    <span> Declined</span>
                                </Menu.Item>   
                            </>                 
                            :
                            <Menu.Item onClick={() => onReview(request)}>                            
                                <Button size='small' type='primary' iconName='View' style={{ marginRight: 5 }} />
                                <span> Review </span>
                            </Menu.Item>
                        }
                    </Menu>
                );
                return (
                    <>
                    <Dropdown overlay={menu}>
                        <a>Actions</a>
                    </Dropdown>                   
                    </>
                )            
            }
        }
    ];

    if (hasError) {
        return (
            <p>Error communicating with the server, try refreshing the page.</p>
        );
    }

    return (
        <div className='content'>
            <h1>Approvals</h1>

            {errorMessage && <Alert type="error" message={errorMessage} className="mb-3" />}
            
            <div className='controls'>
            <Input
                className='margin-tb'
                type='text'
                placeholder='Search existing'
                onChange={({ target: { value } }) => {
                    setSearch(value);
                }}
            />
            </div>
           
            <Table
                columns={columns}
                loading={tableLoading}
                dataSource={!search ? requests : requests.filter(x => x.templateName.toLowerCase().indexOf(search.toLowerCase()) > -1)}
                rowKey='id'
            />
        </div>
    );
}
