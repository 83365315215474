import { Table as KitchensinkTable, Button } from '@bdo/kitchensink';
import React from 'react';
import { appRoutes, buildAppRoute } from '../../config/appRoutes';
import { Link } from 'react-router-dom';
import { useNavigate, useLocation } from 'react-router-dom';
import {GetPageNumber} from "../../utilities/GetPageNumber"
import moment from 'moment';

export default function Table({ isLoading, records, filterTerm, ...props }) {

    const navigate = useNavigate();    
    const { state } = useLocation();
    let currentPage = GetPageNumber(state);
    const onPageChange = async (pageNumber) => {        
        currentPage = pageNumber;
    };
    const columns = [
        {
            title: 'Title',
            key: 'title',
            dataIndex: 'title'
        },
        {
            title: 'Template',
            key: 'templateName',
            render: (record) => (
                <Link to={buildAppRoute(appRoutes.template.view, { id: record.templateId })} target="_blank">
                    {record.templateName}
                </Link>
            )
        },
        {
            title: 'Date created',
            key: 'createdDate',
            dataIndex: 'createdDate',
            render: (record) => (moment(record).format('DD MMM YYYY')),
            sorter: (a, b) => new Date(a.createdDate) - new Date(b.createdDate),
        },
        {
            title: 'Global Portal',
            dataIndex: 'allowGlobalPortalResponses',
            key: 'allowGlobalPortalResponses',
            render: (record) => (record === true ? "Yes" : "No")
        },
        {
            title: 'Passcode',
            dataIndex: 'allowPasscodeResponses',
            key: 'allowPasscodeResponses',
            render: (record) => (record === true ? "Yes" : "No")
        },
        {
            title: 'Anonymous',
            dataIndex: 'allowAnonymousResponses',
            key: 'allowAnonymousResponses',
            render: (record) => (record === true ? "Yes" : "No")
        },
        {
            title: 'Archived',
            dataIndex: 'isArchived',
            key: 'isArchived',
            render: (record) => (record === true ? "Yes" : "No")
        },
        {
            title: 'Actions',
            key: 'actions',
            render: (record) => (
                <>
                    <Button type='primary' onClick={() => navigate(buildAppRoute(appRoutes.capture.manage, { captureName: record?.title, captureId: record?.id }), { state: { pgNo: currentPage } })}>
                        Manage
                    </Button>
                </>
            )
        }
    ];
    

    const data = records.filter(
        (record) => filterTerm?.trim().length === 0 || record.title?.toLowerCase().includes(filterTerm.toLowerCase()) || record.templateName?.toLowerCase().includes(filterTerm.toLowerCase())
    );

    return (
        <>
            <KitchensinkTable
                columns={columns}
                loading={isLoading}
                dataSource={data}
                rowKey='id'
                {...props}
                pagination={{defaultCurrent: currentPage, onChange: onPageChange }}
            />
        </>
    );

}

