import { Button, Input, Alert } from '@bdo/kitchensink';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Table from './Table';
import { apiRoutes, buildApiRoute } from '../../config/apiRoutes';
import { appRoutes, buildAppRoute } from '../../config/appRoutes';
import { useLogging } from '../../hooks/useLogging';
import { useCaptureApi } from '../../hooks/useCaptureApi';

export default function List() {

    const { apiGet } = useCaptureApi();
    const [records, setRecords] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [hasError, setHasError] = useState(false);
    const logging = useLogging();
    const navigate = useNavigate();    
    const [search, setSearch] = useState('');    

    useEffect(() => {
        async function fetchData() {
            try {
                setRecords([]);
                setIsLoading(true);
                setHasError(false)
                const result = await apiGet(buildApiRoute(apiRoutes.captures.list));
                if (result && result.status === 200) {
                    setRecords(result.data.sort(function (a, b) {
                        return +new Date(a.createdDate) - +new Date(b.createdDate);
                      })
                      .reverse());
                }
                else {
                    setHasError(true);
                    logging.error("Failed to fetch captures, non success status code", { result: result });
                }                             
            }
            catch (ex) {
                setHasError(true);
                logging.error("Failed to fetch captures, non success status code", { exception: ex });
            }

            setIsLoading(false);
        }
        fetchData();
    }, []);

    return (
        <>
            <header>
                <h1>My Captures</h1>
                {hasError && <Alert type="error" message="There was an issue fetching records, please try refreshing the page."  className="mb-3"/>}                
                <div className='controls'>
                    <Input
                        className='margin-tb'
                        type='text'
                        placeholder='Search existing'
                        onChange={({ target: { value } }) => {
                            setSearch(value);
                        }}
                    />
                    <Button
                        type='primary'
                        onClick={() => navigate(buildAppRoute(appRoutes.capture.create))}>
                        New
                    </Button>
                </div>
            </header>
            <Table
                isLoading={isLoading}
                records={records}
                filterTerm={search}
            />
        </>
    );
}